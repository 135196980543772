<!-- eslint-disable import/no-cycle -->
<template>
  <component :is="roleData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="roleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <!-- <rbac-edit-tab
      :role-data="roleData"
      class="mt-2 pt-75"
    /> -->
    <div>
      <div>
        <validation-observer ref="updateUserform">
          <!-- User Info: Input Fields -->
          <b-form>
            <b-row>
              <!-- Field: Role Name -->
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Role Name"
                  label-for="roleName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Role Name"
                    rules="required"
                  >
                    <b-form-input
                      id="roleName"
                      v-model="roleData.name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Permissions"
                    rules="required"
                  >
                    <!-- PERMISSION TABLE -->
                    <b-card
                      no-body
                      class="border mt-1"
                    >
                      <b-card-header
                        v-model="permissionIds"
                        class="p-1"
                      >
                        <b-card-title class="font-medium-2">
                          <feather-icon
                            icon="LockIcon"
                            size="18"
                          />
                          <span class="align-middle ml-50">Permission</span>
                        </b-card-title>
                      </b-card-header>
                      <b-table
                        striped
                        responsive
                        class="mb-0"
                        :items="moduleArray"
                      >
                        <template #cell(module)="data">
                          {{ convertModuleName(data.value) }}
                        </template>
                        <template #cell()="data">
                          <b-form-checkbox
                            :checked="data.value.value"
                            :disabled="!data.value.status"
                            @change="onCheckModule(data)"
                          />
                        </template>
                      </b-table>
                    </b-card>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="checkValidate"
      >
        Update
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        :to="{ name: 'role-manager' }"
        variant="outline-secondary"
      >
        Cancel
      </b-button>
      <b-button
        v-if="false"
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Reset
      </b-button>
    </div>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
  BRow, BCol, BForm, BFormGroup, BButton, BTable, BFormInput,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import router from '@/router'
import store from '@/store'
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BFormInput,
    BCol,
    BForm,
    BRow,
    BFormGroup,
    BButton,
    BTable,
    ValidationObserver,
    ValidationProvider,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
  },
  setup() {
    const roleData = ref({})
    const permissionIds = ref([])
    const dataFetchRoles = ref([])
    const toast = useToast()
    const moduleArray = ref([])
    // const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)
    const fetchRole = () => {
      store.dispatch('roleManager/fetchRole', { id: router.currentRoute.params.id })
        .then(response => {
          console.log(response)
          roleData.value = {
            id: response.body.id,
            name: response.body.name,
            permissions: response.body.permissions,
          }
          response.body.permissions.forEach(i => {
            const objIndex = moduleArray.value.findIndex(
              obj => obj.module === i.name.split(':')[0],
            )
            moduleArray.value[objIndex][`${i.name.split(':')[1]}`].value = true
            permissionIds.value.push(
              {
                item: {
                  module: i.name.split(':')[0],
                },
                field: {
                  key: i.name.split(':')[1],
                },
                value: true,
              },
            )
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
    const fetchPermissions = (ctx, callback) => {
      store
        .dispatch('roleManager/fetchPermission')
        .then(response => {
          dataFetchRoles.value = response.body
          dataFetchRoles.value.forEach(element => {
            const objectModule = {
              module: element.name,
              list: {
                value: false,
                status: false,
              },
              read: {
                value: false,
                status: false,
              },
              update: {
                value: false,
                status: false,
              },
              create: {
                value: false,
                status: false,
              },
              delete: {
                value: false,
                status: false,
              },
            }
            moduleArray.value.push(objectModule)
            dataFetchRoles.value.forEach(x => {
              x.permissions.forEach(y => {
                const objIndex = moduleArray.value.findIndex(
                  obj => obj.module === y.name.split(':')[0],
                )
                if (objIndex !== -1) {
                  moduleArray.value[objIndex][y.name.split(':')[1]].status = true
                }
              })
            })
          })
          fetchRole()
        })
        .catch(err => {
          console.log(err)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching permissions list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const onSubmit = () => {
      console.log(permissionIds)
      const arrNamePermissions = permissionIds.value.map(x => `${x.item.module}:${x.field.key}`)
      console.log(arrNamePermissions)
      const payload = {
        name: roleData.value.name,
        permissions: arrNamePermissions,

      }
      store.dispatch('roleManager/updateRole', { id: router.currentRoute.params.id, object: payload })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Update role success',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    setTimeout(() => {
      fetchPermissions()
    }, 0)
    return {
      onSubmit,
      dataFetchRoles,
      moduleArray,
      permissionIds,
      roleData,
      required,
    }
  },
  methods: {
    convertModuleName(data) {
      switch (data) {
        case 'post':
          return 'Local tips'
        case 'program':
          return 'Program'
        case 'postCategory':
          return 'Local tips category'
        case 'programCategory':
          return 'Program category'
        case 'roomService':
          return 'Room service'
        case 'customerFlow':
          return 'Customer'
        case 'general':
          return 'General config'
        case 'rating':
          return 'Rating config'
        case 'account':
          return 'User'
        case 'role':
          return 'Role'
        case 'tracking':
          return 'Tracking'
        default:
          return data
      }
    },
    checkValidate() {
      this.$refs.updateUserform.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
    onCheckModule(data) {
      const valueData = data.value
      this.moduleArray.find(e => e.module === data.item.module)[data.field.key].value = !valueData.value
      if (this.permissionIds.filter(e => e.item.module === data.item.module && e.field.key === data.field.key).length > 0) {
        this.permissionIds.splice(this.permissionIds.findIndex(e => e.item.module === data.item.module && e.field.key === data.field.key), 1)
      } else {
        this.permissionIds.push(data)
      }
      console.log(this.permissionIds)
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
